'use client';
import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Text,
  Card,
  CardBody,
  Image,
  CardHeader,
  Heading,
  CardFooter,
  Stack,
  Link,
} from '@chakra-ui/react';
import apiHelpers from '@/utils/apiHelpers';
import { useRouter } from 'next/navigation';
import FilterContext from '@/components/FilterContext';

const ToInvest = props => {
  const [redirect, setRedirect] = useState(false);

  const [state, setState]=useState({})
  const { push } = useRouter();

  useEffect(() => {
    if (state && redirect) {


    const params = [];
    for (const key in state) {
      if (state.hasOwnProperty(key)) {
        const value = state[key];
    
        // Verificar si el valor es un array
        if (Array.isArray(value)) {
          // Agregar cada elemento del array con corchetes
          value.forEach(item => {
            params.push(`${key}[]=${encodeURIComponent(item)}`);
          });
        } else {
          // Si no es un array, agregar el valor directamente
          params.push(`${key}=${encodeURIComponent(value)}`);
        }
      }
    }
    
    // Unir los parámetros en una cadena
    const queryString = params.join('&');
  
   push(`/propiedades?${queryString}`);
   setRedirect(false);

  }



  }, [redirect]);


  const goToSection = async data => {
    if (data.goTo == '/inversiones') {
      push(data.goTo);
    } else if (data.goTo == 'oportunidades') {

        setState({
          
           isOpportunity: true,
            origin: ['Tokko'],
          }
        );
        setRedirect(true);
     
    } else {
      setState({

  
       
          origin: ['Tokko'],
          steps: [data.goTo],
          postingType: [data.type],
      }
      
      );
      setRedirect(true);
    }
  };




 

  return (
    <Box>
      <Text
        fontSize={{ base: '20px', sm: '25px', md: '25px', lg: '35px' }}
        m={'15px'}
        color="gray.600"
        fontWeight="400"
        fontFamily="Heebo, sans-serif"
        textAlign="center"
        lineHeight="1"
        letterSpacing="2px">
        Opciones de Inversión
      </Text>

      <Center mt={'30px'} mb={'20px'}>
        <Flex alignItems={'center'}>
          <Box
            display="flex"
            transition="transform 0.3s ease"
            justifyContent={'center'}>
            <Grid //display={{sm:'block', md:'block', lg:'flex'}}
              display={'flex'}
              flexDirection={{
                base: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
              }}
              //  templateColumns="repeat(auto-fill, minmax(300px, 1fr))"

              // templateColumns="repeat(300px, 1fr)"
              gap={'20px !important'}
              m={'20px'}>
              {props.toInvest.map((invest, index) => (
                <Box
                  key={index}
                  overflow={'hidden'}
                  position="relative"
                  width={{
                    base: '260px',
                    sm: '350px',
                    md: '50%',
                    lg: '30%',
                    xl: '30%',
                  }}
                  //  maxWidth={{ base:'350px',sm:'100%', md:'100%', lg:"600px" ,xl:'600px'}}
                  // maxW={{sm:'100%', md:'30%', lg:'400px'}}
                  // w="100%"
                  mx="auto"
                  borderRadius={8}
                  //boxShadow="  2px 2px 12px rgba(128, 128, 128, 0.4)"
                >
                  <Box cursor={'pointer'} onClick={() => goToSection(invest)}>
                    {/* Contenedor de la imagen */}
                    <Image
                      src={`/img/${invest.image}`}
                      alt={invest.name}
                      borderRadius={9}
                      width={{
                        base: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '600px',
                        xl: '600px',
                      }}
                      // maxW={{sm:'100%', md:'100%', lg:"500px"}}
                      //h={{sm:"400px", md:"450px", lg:"500px"}}
                      height={{
                        base: '300px',
                        sm: '250px',
                        md: '300px',
                        lg: '330px',
                        xl: '340px',
                      }}
                      objectFit="cover"
                      _hover={{ opacity: '0.8' }}
                    />

                    <Box
                      position="absolute"
                      top="50%"
                      w="100%"
                      h="16%"
                      opacity={'0.9'}
                      bg={'white'}
                      alignContent={'center'}
                      justifyContent={'center'}
                      textAlign={'center'}
                      display={'flex'}
                      flexDir={'column'}
                      // textShadow=" 1px 1px 3px rgba(128, 128, 128, 0.6)"
                    >
                      <Text
                        mt={invest?.subTitle ? '10px' : null}
                        lineHeight={'20px'}
                        fontSize={'1.5rem'}
                        color={'#525252'}
                        alignSelf={'center'}
                        display={'block'}
                        fontWeight="500"
                        fontFamily="Heebo, sans-serif">
                        {invest.name.toUpperCase()}
                      </Text>
                      <Text
                        lineHeight={'16px'}
                        fontSize={'0.8rem'} // Tamaño de texto más pequeño
                        color={'#525252'}
                        alignSelf={'center'}
                        display={'block'}
                        fontWeight="600" // Puedes ajustar el peso de la fuente según tus preferencias
                        fontFamily="Heebo, sans-serif">
                        {invest?.subTitle?.toUpperCase()}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Grid>
          </Box>
        </Flex>
      </Center>
    </Box>
  );
};

export default ToInvest;
