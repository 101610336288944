'use client';
import React,{ useEffect, useState } from 'react'
import { Image,Box,Text } from '@chakra-ui/react'
import './carrousel.css'





const Carrousel = (props) => {
  
    //const carrouselImages= props.images.filter(image=>image.section=='carrousel').map(image=>image.url);
  

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [carrouselImages, setCarrouselImages] = useState([]);
   // const [selectedImage, setSelectedImage] = useState(carrouselImages[0]);
    const [loaded, setLoaded] = useState(false);
    // 2. Define the new text styles
   
    useEffect(() => {
      const images = props.images?.filter((image) => image.section === 'carrousel').map((image) => image.url);
      setCarrouselImages(images);
    }, [props.images]);

    useEffect(() => {
      let interval;
  
      if (props.autoPlay && carrouselImages?.length > 1) {
        interval = setInterval(() => {
          selectNewImage(true);
        }, 3000);
      }
  
      return () => clearInterval(interval);
    }, [props.autoPlay, carrouselImages]);

/*    useEffect(() => {
  let interval;

  if (props.autoPlay || !props.showButtons) {
    interval = setInterval(() => {
      selectNewImage(selectedIndex, carrouselImages);
    }, 3000);
  }

  return () => clearInterval(interval);
}, [props.autoPlay, props.showButtons, selectedIndex, carrouselImages]);*/
    
const selectNewImage = () => {
  // Calcula el nuevo índice avanzando al siguiente en el carrusel
  const newIndex = (selectedIndex + 1) % carrouselImages.length;
  setSelectedIndex(newIndex === 0 ? 0 : newIndex);
};
   /* const selectNewImage = (index, images, next = true) => {
      setLoaded(false);
      setTimeout(() => {
        const condition = next ? selectedIndex < images.length - 1 : selectedIndex > 0;
        const nextIndex = next ? (condition ? selectedIndex + 1 : 0) : condition ? selectedIndex - 1 : images.length - 1;
        setSelectedImage(images[nextIndex]);
        setSelectedIndex(nextIndex);
      }, 800);
    };*/
  
    const previous = () => {
      selectNewImage(selectedIndex, carrouselImages, false);
    };
  //['base, sm, md, lg, xl']
    const next = () => {
      selectNewImage(selectedIndex, props.images);
    };

    //src={`/img/${selectedImage}`} alt="Properties" className={loaded ? 'carrouselImage loaded' : 'carrouselImage'}onLoad={() => setLoaded(true)}
    return (
      <>
       <Box position={'relative'} w={'full'} overflow={'hidden'} >
  
        <Text zIndex={3} fontSize={{base: '10px',sm:'22px', md: '33px', lg: '45px' }} position={'absolute'}  top={'35%'}   left={'50%'}
        transform={'translateX(-50%)'} // Tamaño de la fuente
      color="white"    // Color del texto
 
      //WebkitTextStroke="2px black"
        fontFamily= "Poppins, sans-serif"
        fontWeight= "400"

      //textAlign="center"  // Alineación del texto (left, center, right, justify)
      lineHeight="1"     // Altura de línea
     display={{ base: 'none',  md: 'block', lg: 'block' }}
      >
      ENCONTRÁ TU LUGAR
 
     </Text>
      <Box background="white" width={'100%'}  zIndex={2} >
     
      <Image
       src={carrouselImages?.length ? carrouselImages[selectedIndex] : props.defaultImg}
           //   src={selectedImage==undefined ? props.defaultImg : selectedImage}
              alt="Properties"
              className={'carrouselImage loaded'}
              onLoad={() => setLoaded(true)}
              width={'100vw'}
              objectFit='cover'
              maxHeight={props.breakpoints}
            />
        
          
         
      </Box>
      
      
{/*
      <Box>
        {props.showButtons ? (
          <>
            <button onClick={previous}>{"<"}</button>
            <button onClick={next}>{">"}</button>
          </>
        ) : (
          <></>
        )}
        </Box>*/}
    </Box>
      

        

      </>
    );
}

export default Carrousel
